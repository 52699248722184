import React from 'react';
import styled from 'styled-components';
import { graphql, StaticQuery, Link } from 'gatsby';
import Img from 'gatsby-image';
import { createBreakpoint } from 'react-use';

import Layout from '@components/common/layout';
import ContactIntro from '@components/en/common/contact-intro';
import Hero from '@components/en/common/hero';
import PageIntro from '@components/common/page-intro';
import ContentsIntro from '@components/common/contents-intro';
import Btn from '@components/common/btn';

import Hero__mds from '@img/svg/strength/hero__mds.svg';
import Mds01 from '@img/svg/strength/mds01.svg';
import Mds02 from '@img/svg/strength/mds02.svg';
import Mds03 from '@img/svg/strength/mds03.svg';

import Column from '@components/strength/column';
import Nav from '@components/strength/nav';
import { NavCont, Container } from '@components/strength/styles';

import { mixin, color, breakpoint } from '@src/settings';
const useBreakpoint = createBreakpoint(breakpoint);

const StrengthPage = ({ data }) => {

	const bp = useBreakpoint();

	return (
	<Layout baseBgColor={ color.paleGray }>
		<Hero
			img={ data["heroImg"] }
			title={<Hero__mds />}
		/>

		<PageIntro
			title={<>Pioneer of body parts manufacturing in Malaysia</>}
			subtitle={<>Specializing in IKKANSEISAN System and implementing TPS with a global perspective, we are always passionate to pursue better quality, on-time delivery and cost competitiveness to meet customer's satisfaction.<br /> As a pioneer in the body parts manufacturing field, we will continue to grow our people and to contribute to the Automotive Industry.</>}
		/>

		<Container>	
			<Column
				className="column"
				num={ 1 }
				img={ bp == "pc" ? data["Strength_1_200311"] : data["Strength_1_sp"] }
				imgPos="65% 50%"
				title="HUMAN DEVELOPMENT"
				cap={<>The most essential thing for a manufacturing company is growing the people because our employees are the most important asset to the company. <br />From the operation members to the top managements, we always work and learn together to promote continous improvements and problem solving. <br />With the good communications and teamwork, our people have brighten up the work environment, allowing us to towards one goal in one team.</>}
			/>

			<Column
				className="column"
				num={ 2 }
				img={ bp == "pc" ? data["Strength_2"] : data["Strength_2_sp"] }
				imgPos="90% 50%"
				title="IKKANSEISAN System"
				cap={<>IKKANSEISAN System also known as One-Stop-Solution, is our specialty.<br /> We provide the exact services to fulfill our customer needs,  including tool & die design and fabrication, stamping, welding, assembly, all under the same factory.<br /> Being the best IKKANSEISAN System in Malaysia, it allows us to achieve higher quality level in a shorter development lead time.</>}
				to="/en/products"
				btnTxt="Our Products"
				isReversed
			/>

			<Column
				className="column"
				num={ 3 }
				img={ bp == "pc" ? data["Strength_3_200311"] : data["Strength_3_sp"] }
				title="TECHNICAL CAPABILITIES"
				cap={<>With our philosophy of constantly pursuing new technologies, the second generation Managing Director had the opportunity to gain his knowledge and experience for 3 years at Toyota Auto Body in Gifu Japan.<br /> We incoroprate Toyota Production System (TPS) as the basis of our business operations.<br /> We also continue to build our own technical capabilities by learning the latest technology as well as skilled techniques from our Japanese Advisors and Technology Assistance (TA) Partners who have many years of experience.</>}
			/>
		</Container>

		<ContentsIntro
			firstItem="services"
			secondItem="facility"
		/>
	</Layout>
	)
}


export const query = graphql`
	query strengthImgs_en{

		heroImg: file( relativePath: { eq: "strength/hero-img.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Strength_1_200311: file( relativePath: { eq: "strength/strength-1_200311.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Strength_2: file( relativePath: { eq: "strength/strength-2_200624.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Strength_3_200311: file( relativePath: { eq: "strength/strength-3_200311.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Strength_1_sp: file( relativePath: { eq: "strength/strength-1--sp.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Strength_2_sp: file( relativePath: { eq: "strength/strength-2--sp_200624.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Strength_3_sp: file( relativePath: { eq: "strength/strength-3--sp.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
	},

		Subblock_service: file( relativePath: { eq: "common/subblock_service.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},


		Subblock_facility: file( relativePath: { eq: "common/subblock__facility.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},
	}
`

export default StrengthPage
